@font-face {
  font-family: "Montserrat Bold";
  src: url("fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Montserrat";
  src: url("fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat Medium";
  src: url("fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Montserrat ExtraBold";
  src: url("fonts/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: "Montserrat SemiBold";
  src: url("fonts/Montserrat-SemiBold.ttf");
}

body {
  font-family: "Montserrat";
  /* margin-bottom: 40px; */
  width: 100%;
}

#root {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.leaflet-container {
  height: 400px;
  width: 100%;
}
.column h2,
h3 {
  font-family: "Montserrat";
  font-weight: normal;
}

.column h1 {
  margin-bottom: 0px;
}
.App-header {
  background-color: white;
  display: flex;
  justify-content: center;
}

.App-title {
  cursor: pointer;
}

.App-logo {
  height: 40px;
}

.Name {
  position: absolute;
  bottom: 55%;
  left: 20px;
}
.Name h2 {
  margin-bottom: 0px;
  font-family: "Montserrat Bold";
  color: white;
  text-shadow: 2px 2px 4px #ad1349;
  margin-top: 10px;
}
.Name h2 em {
  font-size: 0.8em;
}

.navigation {
  background-color: #db3b60;
  padding: 40px 0 40px 0;
  width: 100%;
}

.navigation .menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.navigation .left {
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.navigation .left .item {
  margin-right: 20px;
  color: #fff;
}

.navigation .right {
  margin-right: 40px;
}

.navigation .right button {
  padding: 8px 0;
  margin: 0 0 0 37px;
  cursor: pointer;
  color: #fff;
}

.navigation .nav-text {
  margin-left: 40px;
  color: #fff;
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}

.Examples-Container {
  display: flex;
}
h1 {
  font-size: 4em;
  font-family: "Montserrat ExtraBold";
  color: #db3b60;
  line-height: 1em;
  /* width: 90%; */
  margin-right: 0px;
}

a {
  text-decoration: none;
  color: #db3b60;
}
p {
  letter-spacing: 0.001em;
}
.home a:hover {
  background-image: -webkit-linear-gradient(
    left,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-image: linear-gradient(
    to right,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-position: -100%;
  background-size: 200%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
button {
  outline: none;
  border-radius: 25px;
  margin-left: 10px;
  background-color: transparent; /* Green */
  border: none;
  color: #db3b60;
  padding: 8px 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
/* hover styles */
.bigcard:hover {
  filter: none;
}
.cardimg button:hover {
  color: #ffb6c1;
}
a:hover {
  color: #ffb6c1;
}
#box a:hover {
  color: #ffb6c1;
}
.map a:hover {
  color: #0078a8;
}

p a:hover {
  background-image: -webkit-linear-gradient(
    left,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-image: linear-gradient(
    to right,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-position: -100%;
  background-size: 200%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  color: #db3b60;
}
h2 a:hover {
  background-image: -webkit-linear-gradient(
    left,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-image: linear-gradient(
    to right,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-position: -100%;
  background-size: 200%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  color: #db3b60;
}
h3 a:hover {
  background-image: -webkit-linear-gradient(
    left,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-image: linear-gradient(
    to right,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-position: -100%;
  background-size: 200%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  color: #db3b60;
}
.enter {
  font-size: 2em;
}

.body {
  margin: 0;
  padding: 40px 120px 0px 120px;
}
/* TestCard 2019 Page */
.card2019 .Description {
  margin-bottom: 25px;
}
.card2019 .bigcard {
  border-style: dashed;
  border-width: 3px;
}
/* TestCard 2020 Page */

.bigcard {
  border-width: 3px;
  border-radius: 15px;
  border-style: solid;
  width: 450px;
  margin-right: 40px;
  padding-bottom: 0px;
  position: relative;
  border-color: #ff2457;
  margin-bottom: 0px;
  height: 60vh;
  transition: 0.4s;
}

.bigcard:hover {
  border-color: #a8314d;
  color: #a8314d;
  transition: 0.4s;
  transform: translate(-5px, -10px);
  box-shadow: 5px 10px 25px #888888;
}
/* .bigcard img:hover {
  filter: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
} */

.Description {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  color: darkred;
  overflow: hidden;
}

.Description p {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  width: 350px;
}

.card-title {
  font-size: 1.1vw;
  margin: 0;
  padding-left: 1.5vw;
  font-family: "Montserrat Bold";
}

.card-author {
  font-size: 0.9vw;
  margin: 0;
  padding-top: 0.5vh;
  padding-left: 1.5vw;
  font-family: "Montserrat Bold";
}
/* clamp styles */
.clamp-lines {
  border-radius: 0.25em;
  font-family: sans-serif;
  /* margin: 1em; */
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  padding-top: 1vh;
  font-size: 0.8vw;
}

.clamp-lines > span {
  display: block;
}

/* clamp custom styles */

.clamp-lines.custom-class .clamp-lines__button {
  border: 1px solid transparent;
  display: block;
  margin: 0.75em auto 0;
  padding: 0.5em;
  text-align: center;
  transition: all 0.2s linear;
  width: 100%;
}

.cardimage img {
  border-radius: 12px 12px 0px 0px;
  opacity: 0.9;
  width: 20vw;
  height: 22vh;
  /* filter: blur(3px); */
}
.cardimage:hover {
  filter: none;
}
.cardimage {
  background: #db3b60;
  border-radius: 12px 12px 0px 0px;
  height: 22vh;
  overflow: hidden;
}
.bigcard {
  width: 20vw;
  margin-bottom: 25px;
  margin-right: 8px;
  margin-left: 8px;
}
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.gallery .row {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.body.row {
  flex-wrap: wrap;
}
.row a {
  margin-right: 15px;
}
/* 2020 Page Borderbox */

#box {
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.gradient-border {
  --borderWidth: 4px;
  background: #fff2f2;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    60deg,
    #fec3e0,
    #ff77b9,
    #ff1f8f,
    #ff4ef9,
    #ff07f7
  );
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

/* 2020 Page */
.Description2020 {
  margin-top: 20px;
}
.filterlist {
  font-family: "Montserrat ExtraBold";
  margin-bottom: 20px;
  /* letter-spacing: 1.5px; */
}

.filterlist img {
  height: 100%;
  max-height: 30px;
  width: 100%;
  max-width: 30px;
  margin-right: 10px;
}

.filterlist #filter-by {
  text-decoration: underline dotted #ffd3de;
}
.filterlist button {
  letter-spacing: 1.5px;
  font-family: "Montserrat ExtraBold";
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #db3b60;
  display: inline-block;
  background-color: transparent;
  color: #db3b60;
  border-radius: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 0px;
  padding-left: 15px;
  font-size: 30px;
}
#Visual.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#Game.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#Data.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#World.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#All.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#Text.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#Sound.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#Educational.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#Teaching.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#Simulation.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#Tool.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#Camera.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
.filterlist button:hover {
  -webkit-text-fill-color: pink;
  display: inline-block;
  color: #db3b60;
  text-shadow: 2px 2px #ff1f8f;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.filterlist h2 {
  -webkit-text-fill-color: #db3b60;
  font-size: 30px;
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ff2457;
  text-shadow: 2px 2px #ff1f8f; */
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
}

/* DIFFICULTY BUTTON */

.difficultylist {
  font-family: "Montserrat ExtraBold";
  margin-bottom: 20px;
  letter-spacing: 1.5px;
}
.difficultylist button {
  letter-spacing: 1.5px;
  font-family: "Montserrat ExtraBold";
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #db3b60;
  display: inline-block;
  background-color: transparent;
  color: #db3b60;
  border-radius: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 0px;
  font-size: 30px;
}

#Beginner.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#Intermediate.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}
#Advanced.active {
  -webkit-text-fill-color: pink;
  text-shadow: 2px 2px #ff1f8f;
}

.difficultylist button:hover {
  -webkit-text-fill-color: pink;
  display: inline-block;
  color: #db3b60;
  text-shadow: 2px 2px #ff1f8f;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.difficultylist h2 {
  -webkit-text-fill-color: #fff2f2;
  font-size: 30px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ff2457;
  text-shadow: 2px 2px #ff1f8f;
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
}

.img-description {
  border: 5px;
  border-color: #ff2457;
  background-color: #ff2457;
  color: white;
}

.img-description-text {
  margin: 0;
  padding: 1vh;
  font-size: 0.55vw;
  text-align: center;
  height: 6vh;
}
/* ARCHIVE PAGE */

#intro2019 h1 {
  color: #ff577e;
  margin-top: 20px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px #ad1349;
}

#archive2021 h1 {
  color: #ff577e;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 40px;
}

#archive2020 h1 {
  color: #ff577e;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 40px;
}

#archive2019 h1 {
  color: #ff577e;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 40px;
}

#archive2021,
#archive2020,
#archive2019 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 1em;
  width: 85%;
  border-radius: 25px;
}

.view,
.readmore {
  font-size: 15px;
  cursor: pointer;
  flex: 1 1 auto;
  border-radius: 20px;
  margin: 10px;
  padding: 20px;
  text-align: center;
  background-size: 100% auto;
  color: #ff577e;
  /* box-shadow: 0 0 10px #eee; */
  background-color: #f5f5f5;
}

.translation-banner {
  text-align: center;
  background-color: #db3b60;
  color: white;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.disclaimer-link {
  color: white;
  text-decoration: underline;
}

.intro-link {
  color: #000;
  text-decoration: underline;
}

.intro2020 h1 {
  text-align: center;
  font-family: "Montserrat ExtraBold";
  font-weight: bolder;
  font-size: 3.5em;
  /* letter-spacing: 0.1em; */
  /* -webkit-text-fill-color: #fff2f2;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ff2457; */
  /* color: white;
  text-shadow: 2px 2px #fec3e0, 4px 4px #ff77b9, 6px 6px #ff1f8f; */
  margin-bottom: 20px;
  margin-right: 0px;
  margin-top: 20px;
}
.cover2020 {
  margin-top: 30px;
  margin-bottom: 140px;
}
.cover2020 h1 {
  font-family: "Montserrat ExtraBold";
  font-weight: bolder;
  font-size: 3.75em;
  margin-right: 20px;
  letter-spacing: 0.1em;
  -webkit-text-fill-color: #fff2f2;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ff2457;
  color: white;
  margin-top: 0px;
  text-shadow: 2px 2px #fec3e0, 4px 4px #ff77b9, 6px 6px #ff1f8f;
}
img.downarrow {
  -webkit-text-stroke-color: #fff2f2;
  -webkit-text-stroke-width: 8px;
  -webkit-text-stroke-color: #ff8ba6;
  text-shadow: -2px -2px #ff77b9;
  margin-left: 0px;
  margin: 0 auto;
  display: block;
  width: 40px;
  height: 30px;
  margin-top: 65px;
}
img.downarrow {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
img.downarrow {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-8px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-8px);
  }
}

.intro2020 p {
  margin: 0px;
  color: #ff8ba6;
  text-align: center;
}
.flex p em {
  margin-top: 20px;
  color: #ff8ba6;
  font-size: 20px;
}
.column {
  flex: 50% 1;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1vh;
}

/* 2020 visualization */

.twentyviz h1 {
  font-family: "Montserrat ExtraBold";
  font-size: 2vw;
}
.twentyviz h1 a {
  float: right;
}
.twentyviz h2 {
  line-height: 30px;
  display: inline;
  color: black;
  font-size: 1vw;
}
.twentyviz a:hover {
  background-image: -webkit-linear-gradient(
    left,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-image: linear-gradient(
    to right,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-position: -100%;
  background-size: 200%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  color: #db3b60;
}
.twentyviz button {
  padding: 0px 0px 0px 10px;
  margin-right: 10px;
  font-size: 1.5em;
  font-family: Montserrat;
  margin-left: 0px;
}
.twentyviz button:hover {
  background-image: -webkit-linear-gradient(
    left,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-image: linear-gradient(
    to right,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-position: -100%;
  background-size: 200%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  color: #db3b60;
}

#portfolio {
  vertical-align: 50%;
}

#portfolio svg {
  width: 0.75em;
}

canvas {
  display: block;
}
#graph1.active {
  background-image: -webkit-linear-gradient(
    left,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-image: linear-gradient(
    to right,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-position: -100%;
  background-size: 200%;
  color: #db3b60;
}
#graph2.active {
  background-image: -webkit-linear-gradient(
    left,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-image: linear-gradient(
    to right,
    white 50%,
    rgb(255, 228, 233) 50%
  );
  background-position: -100%;
  background-size: 200%;
  color: #db3b60;
}
/* card 2020 */
h1.tenpx {
  margin-bottom: 0px;
}
.box.full {
  width: 360px;
}
.Name2020 {
  position: absolute;
  top: 5%;
  left: 10px;
}
.Name2020 h2 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #db3b60;
  font-size: 1.45em;
  margin-bottom: 0px;
  font-family: "Montserrat ExtraBold";
  margin-top: 10px;
  -webkit-text-fill-color: white;
  text-shadow: 2px 2px #db3b60;
}
.Name2020 a:hover {
  -webkit-text-fill-color: pink;
}
.Name2020 h2 em {
  font-size: 0.8em;
}

.links {
  text-align: center;
  display: flex;
}
.links a {
  margin-right: 0px;
  text-align: center;
}
.box {
  padding: 5px 0px 0px 0px;
  height: 2.5vh;
  border: #ff2457;
  border-top-style: solid;
  border-bottom-style: solid;
  width: 10vw;
  font-size: 1vw;
}
.links a:first-child {
  border: #ff2457;

  border-right-style: solid;
}

.links a:first-child:hover {
  border: #ff2457;

  border-right-style: solid;
}

.box:hover {
  color: white;
  background-color: #ffb6c1;
}
.tags {
  margin: 0px;
  display: inline-block;
  padding: 0px;
  overflow: auto;
  white-space: nowrap;
  width: 20vw;
}
.tags ul {
  list-style: none;
  display: inline-block;
  margin: 0px;
  padding: 0px;
}
.tags li {
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 10px;
  max-height: 30px;
  display: inline-block;
  color: transparent;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  padding-left: 0px;
  padding-right: 0px;
}
.tags a:hover {
  background-color: #ffb6c1;
  color: white;
}
.tags a {
  font-size: 0.8vw;
  padding: 6px 14px;
  overflow: wrap;
  background-color: #ffe8e8;
  border-radius: 26px;
  margin-left: 0px;
  margin-right: 0px;
}

/* About Page */

.about-h2 {
  font-size: 2vw;
  font-family: "Montserrat ExtraBold";
  color: #db3b60;
  text-align: center;
}

.abt-col1 {
  width: 100vw;
}

.abt-col2 {
  width: 30vw;
}

.about-body-text {
  /* width: 60vw; */
  /* padding-right: 2vw; */
  font-size: 1.1vw;
  text-align: center;
}

.about-learn-more {
  text-align: center;
  font-size: 1.1vw;
  font-family: "Montserrat Bold";
  color: #db3b60;
  padding-top: 2vh;
}

.about-body-text p {
  padding-bottom: 2vh;
}
.about-text-2022 {
  margin: 10vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.about-row {
  display: flex;
  flex-direction: row;
}

.about-img {
  width: 10vw;
  margin: 0;
}

.about-img2 {
  width: 10vw;
  margin: 0;
  padding-left: 5vw;
  padding-top: 5vh;
}

.home p {
  width: 85%;
  margin-left: 70px;
  line-height: 1.5em;
  display: inline-block;
  margin-top: 15px;
}
.home h1 {
  display: inline-block;
  margin-bottom: 0px;
  margin-top: 10px;
  font-size: 3.5em;
  text-decoration: underline dotted #ffd3de;
}
.home {
  margin-top: 80px;
  margin-left: 130px;
}
.home img {
  height: 100%;
  max-height: 50px;
  width: 100%;
  max-width: 50px;
  margin-right: 10px;
}
/* Details Page */
.img-gallery {
  float: left;
  height: 510px;
  width: 728px;
}

.gallery {
  overflow: hidden;
  padding-top: 3vh;
  margin: 0 auto;
}
.archive {
  margin: 0 auto;
}
.details-title h1 {
  text-align: center;
  font-size: 3vw;
}

.details-title p {
  text-align: center;
  font-size: 1vw;
  padding-left: 7vw;
  padding-right: 7vw;
}

.details-header {
  width: 1000px;
  margin: auto;
  margin-top: 60px;
}
.detailsbody p {
  font-size: 16px;
  white-space: pre-line;
  line-height: 1.6;
}
.detailsbody {
  margin: 40px 300px 120px 300px;
}
.details-header h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.col {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.details-image {
  width: 425px;
  height: auto;
  margin-right: 60px;
}
.details-image img {
  width: 430px;
  height: auto;
  margin-right: 40px;
  border-radius: 25px;
  border: dotted #db3b60;
  padding: 5px;
}
.details-header h1 {
  font-size: 48px;
  font-family: "Montserrat Medium";
  margin-bottom: 10px;
}
.detailsbody h1 {
  font-size: 48px;
  font-family: "Montserrat Medium";
  margin-bottom: 10px;
}
.details-header h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  font-family: "Montserrat";
}
.detailsbody h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  font-family: "Montserrat";
}
.QnA h2 {
  margin-top: 40px;
}
.detailsbody {
  font-size: 18px;
}
.details-header {
  font-size: 18px;
}
.small {
  font-size: 16px;
  font-style: italic;
}
.center {
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 900px) {
  .details-header {
    width: 760px;
  }
  .column {
    flex: 100%;
  }

  .body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .detailsbody {
    margin-left: 20px;
    margin-right: 20px;
  }
  .details-image img {
    width: 90vw;
    margin-right: 0px;
    margin-left: 0px;
    max-width: 100%;
  }
  .detailsbody .row {
    flex-wrap: wrap;
  }
  .detailsbody h1 {
    margin-top: 20px;
  }
  .home {
    margin-top: 30px;
    margin-left: 20px;
  }
  .gallery h1 {
    width: 100%;
  }
  .home p {
    width: 100%;
  }
  .home p {
    margin-left: 0px;
  }
}

.to-them {
  margin-top: 15vh;
}
.to-them h1 {
  margin-bottom: 5vh;
}

.link-showcase {
  margin-top: 10vh;
  padding-bottom: 10vh;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .details-header {
    margin-top: 60px;
    margin-right: auto;
    margin-left: auto;
    width: 94vw;
  }
  .navigation {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .navigation .right {
    margin-left: 40px;
    margin-right: 0;
  }

  .navigation .right button {
    margin: 10px 37px 0 0;
  }
}

@media (max-width: 426px) {
  .intro2020 {
    text-align: left;
  }
  .filterlist button {
    font-size: 24px;
    -webkit-text-stroke-width: 0.75px;
  }

  .card2019 .bigcard {
    width: 90vw;
  }
  .gallery h1 {
    font-size: 48px;
  }
  .intro2020 h1 {
    text-align: left;
    font-size: 48px;
  }
  .filterlist button {
    text-align: left;
  }
  .difficultylist button {
    text-align: left;
    font-size: 24px;
    -webkit-text-stroke-width: 0.75px;
  }
  .cover2020 h1 {
    font-size: 3rem;
  }
  .twentyviz h1 {
    font-size: 40px;
  }
}

@media (max-width: 375px) {
  .navigation .left {
    margin-left: 20px;
  }
  .navigation .right {
    margin-left: 20px;
  }
}

/* 2022 */

.navigation2022 {
  background-color: #fff;
  padding: 40px 0 40px 0;
  width: 100%;
  position: sticky;
  top: 0;
}

.navigation2022 .menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navigation2022 .left {
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.navigation2022 .left .item {
  margin-right: 20px;
  color: #db3b60;
}

.navigation2022 .left .item:hover {
  color: #ff8ba6;
}

.navigation2022 .right button:hover {
  color: #ff8ba6;
}

.navigation2022 .right {
  margin-right: 40px;
}

.navigation2022 .right button {
  padding: 8px 0;
  margin: 0 0 0 37px;
  cursor: pointer;
  color: #db3b60;
}

.navigation2022 .nav-text {
  margin-left: 40px;
  color: #db3b60;
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}

#landing-img {
  /* position: absolute; */
  width: 100%;
  /* padding-top: 7vh; */
  /* margin-bottom: 10vh;
  margin-top: -25vh; */
}

#connect-title {
  width: 60vw;
}

.title-2022 {
  margin-left: 20vw;
  /* margin-bottom: -30vh;
  margin-top: 35vh; */
}

.cover2022 {
  background-color: #d7dded;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cover-text-2022 {
  /* position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 50%; */
  text-align: center;
  /* margin-bottom: -5vh; */
  /* margin-top: 5vh; */
}

#h1-2022 {
  font-size: 7vw;
  letter-spacing: 1vw;
  /* margin-top: -5vh; */
  font-family: "Montserrat Bold";
  color: #db3b60;
  /* line-height: 1em; */
  /* width: 90%; */
  /* margin-right: 0px; */
}
#h2-2022 {
  /* font-size: 10vw;
  letter-spacing: 1vw;
  margin-top: -5vh; */
  /* margin-top: -7vh; */
  font-family: "Montserrat SemiBold";
  color: black;
  /* line-height: 1em; */
  /* width: 90%; */
  /* margin-right: 0px; */
}

.map-viz-2022 {
  margin: 10vw;
  padding-bottom: 2vh;
  text-align: center;
}

.footer {
  margin-top: 40px;
  position: relative;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 17%;
  text-align: center;
}

.footer-img {
  width: 15vw;
}
